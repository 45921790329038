.react-flow__node-custom {
    font-size: 10px;
    width: 180px;
    background: #f5f5f6;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 2px;
}

.react-flow__node-custom .react-flow__handle {
    top: 24px;
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
}

.react-flow__node.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.react-flow__node.annotation {
    border-radius: 0;
    text-align: left;
    background: white;
    border: none;
    line-height: 1.4;
    width: 225px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
}

.react-flow__node.annotation .react-flow__handle {
    display: none;
}

.custom-node__header {
    padding: 8px 10px;
    border-bottom: 1px solid #e2e8f0;
}

.custom-node__body {
    padding: 10px;
}

.custom-node__select {
    position: relative;
    margin-bottom: 10px;
}

.custom-node__select select {
    width: 100%;
    margin-top: 5px;
    font-size: 10px;
}


.react-flow__node-editable {
    z-index: 0;
    transform: translate(150px, -250px);
    pointer-events: all;
    visibility: visible;
    background: rgb(214, 213, 230);
    color: rgb(51, 51, 51);
}


.action-panel {
    padding: 5px 10px;
}

/* .react-flow__node-toolbar {
    display: flex;
    gap: 8px;
}

.react-flow__panel,
.react-flow__node-toolbar {
    background-color: var(--panel-background);
    box-shadow: var(--panel-shadow);
    border-radius: 8px;
    padding: 8px;
} */


.react-flow {
    --background: #e2e8f0;
    --panel-background: #f8fafc;
    --panel-shadow: 0 0 4px 0 rgb(0 0 0 / .2);
    --text: #0a0a0a;
    background-color: var(--background);
}

.react-flow__panel.top {
    margin-top: 1.5em;
}

.react-flow__attribution {
    display: none;
}


.jsoneditor {
    height: calc(100vh - 10rem);
}

/* .jsoneditor .jsoneditor-menu {
    background-color: #f8fafc;
    color: #0a0a0a;
}

.jsoneditor .jsoneditor-menu > button {
    color: #0a0a0a;
} */

.jsoneditor-menu a.jsoneditor-poweredBy {
    display: none;
}